import { User } from "@/types/user";

export const PERM_CREATE_COMPANY = "create-company";
export const PERM_UPDATE_COMPANY = "update-company";
export const PERM_CREATE_PATIENT = "create-patient";
export const PERM_UPDATE_PATIENT = "update-patient";
export const PERM_CREATE_TEST = "create-test";
export const PERM_UPDATE_TEST = "update-test";
export const PERM_CREATE_DOCTOR = "create-doctor";
export const PERM_UPDATE_DOCTOR = "update-doctor";
export const PERM_CREATE_INVOICE = "create-invoice";
export const PERM_UPDATE_INVOICE = "update-invoice";
export const PERM_DELETE_INVOICE = "delete-invoice";
export const PERM_LIST_PENDING_REPORTS = "pending-test-reports";
export const PERM_LIST_COMPLETED_REPORTS = "completed-test-reports";
export const PERM_CREATE_TEST_REPORT = "feed-test-results";
export const PERM_RECALL_TEST_REPORT = "recall-test-report";
export const PERM_INVITE_USER = "invite-user";
export const PERM_UPDATE_USER = "update-user";
export const PERM_VIEW_REFERRAL_REPORT = "view-referral-report";
export const PERM_VIEW_SALES_DETAIL_REPORT = "view-sales-detail-report";
export const PERM_CREATE_TEST_PACKAGE = "create-test-package";
export const PERM_UPDATE_TEST_PACKAGE = "update-test-package";
export const PERM_LIST_PATIENTS = "list-patient";
export const PERM_LIST_TESTS = "list-test";
export const PERM_LIST_DOCTORS = "list-doctor";
export const PERM_LIST_INVOICES = "list-invoice";
export const PERM_LIST_TEST_PACKAGES = "list-test-package";
export const PERM_CREATE_PRICE_LIST = "create-price-list";
export const PERM_UPDATE_PRICE_LIST = "update-price-list";
export const PERM_VIEW_PRICE_LISTS = "view-price-lists";
export const PERM_DOWNLOAD_REFERRAL_REPORT = "download-referral-report";
export const PERM_DOWNLOAD_SALES_DETAIL_REPORT = "download-sales-detail-report";
export const PERM_VIEW_SALES_SUMMARY = "view-sales-summary";

export default function isAuthorizedTo(user: User, perm: string): boolean {
  return user.permissions.indexOf(perm) != -1;
}
