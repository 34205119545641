<script setup lang="ts">
import { ref, onMounted, computed, provide, readonly } from "vue";
import { useRouter, useRoute } from "vue-router";
import { menuItems, RouteMeta } from "./router";
import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import { User } from "@/types/user";
import PrivacyPolicy from "@/components/privacy-policy.vue";
import { refreshToken, signoff } from "@/service/auth-service";
import { fetchSelfCompany, getLocation, setLocation } from "@/service/company-service";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import { Branch, Company } from "@/types/company";
import { useToast } from "primevue/usetoast";
import { KEY_USER, KEY_BRANCH, KEY_COMPANY } from "@/helper/keys";

const route = useRoute();
const toast = useToast();
const fullScreen = ref(false);
const router = useRouter();
const user = ref<User>();
const company = ref<Company>();
const branch = ref<Branch>();
const temp_branch = ref<Branch>();
provide(KEY_USER, readonly(user));
provide(KEY_COMPANY, readonly(company));
provide(KEY_BRANCH, readonly(branch));
const timer = ref<NodeJS.Timer>();
const menu = ref();
const rememberBranch = ref(false);
const showBranchSelection = ref(false);
const showPrivacyPolicy = ref(false);
const shortcuts = computed(() => {
  if (user.value == undefined) {
    return [];
  }
  return menuItems.filter((link) => {
    if (!link.requires) return true;
    for (const perm of link.requires) {
      if (user.value?.permissions.includes(perm)) return true;
    }
    return false;
  });
});
const items = [
  { label: "Your Profile", icon: "pi pi-fw pi-user", command: () => router.push({ name: "user-profile" }) },
  { label: "Change Branch", icon: "pi pi-fw pi-cog", command: () => (showBranchSelection.value = true) },
  { label: "Privacy Policy", icon: "pi pi-fw pi-file", command: () => (showPrivacyPolicy.value = true) },
  { separator: true },
  { label: "Log Out", icon: "pi pi-sign-out", command: () => signoff().then(() => ressurectSession()) },
];
const avatarLabel = computed(() => {
  if (user.value != undefined) {
    let nameParts = user.value.name.split(" ");
    return nameParts
      .map((part) => part[0])
      .join("")
      .substring(0, 2)
      .toUpperCase();
  }
  return "";
});

function ressurectSession(u?: User) {
  if (u != undefined) {
    user.value = u;
    timer.value = setInterval(refreshToken, 5 * 60 * 1000);
    fetchSelfCompany()
      .then((result) => (company.value = result))
      .catch(({ error }) => toast.add({ severity: "error", summary: "Unable to load company", detail: error }))
      .then(() => getLocation())
      .then((result) => (branch.value = result))
      .catch(() => {
        if (company?.value?.branches.length == 1) {
          temp_branch.value = company?.value?.branches[0];
          setBranch();
        } else {
          showBranchSelection.value = true;
        }
      });
    router.push(route.redirectedFrom || { name: "home" });
  } else {
    clearInterval(timer.value);
    user.value = undefined;
    router.push({ name: "login" });
  }
}

function setBranch() {
  setLocation(temp_branch.value?.id!!, rememberBranch.value).then(() => ((showBranchSelection.value = false), (branch.value = temp_branch.value)));
}

router.beforeEach((to, _, next) => {
  let requiresAuth = (to.meta as RouteMeta).requiresAuth ? true : false;
  if (requiresAuth && user.value == null) {
    next({ name: "login" });
  } else {
    next();
  }
});

router.afterEach((to) => {
  fullScreen.value = (to.meta as RouteMeta).fullScreen ? true : false;
});

onMounted(() => refreshToken().then((result) => ressurectSession(result)));
</script>

<template>
  <div :class="{ 'align-items-center flex justify-content-center': fullScreen }" class="bg-black-alpha-20 min-h-screen">
    <Toast />
    <Dialog :draggable="false" @hide="() => (showBranchSelection = false)" @show="() => (temp_branch = branch)" v-model:visible="showBranchSelection" modal header="Default Branch" :closable="branch != null" class="lg:col-4 md:col-6 col-9">
      <div class="grid">
        <div class="field col-12">
          <label for="branch">Please select your current branch location</label>
          <Dropdown id="branch" v-model="temp_branch" :options="company?.branches" option-label="name" placeholder="Select a branch" class="w-full" />
        </div>
        <div class="col-12 flex flex-row flex-wrap justify-content-between">
          <div class="flex align-items-center">
            <Checkbox v-model="rememberBranch" binary id="rememberBranch" />
            <label for="rememberBranch" class="ml-2">Remember my choice</label>
          </div>
          <Button label="Set Branch" @click.throttle="setBranch" />
        </div>
      </div>
    </Dialog>
    <Dialog :draggable="false" @hide="() => (showPrivacyPolicy = false)" v-model:visible="showPrivacyPolicy" modal header="Privacy Policy" :closable="true" class="lg:col-6 md:col-8 col-11">
      <div class="grid">
        <PrivacyPolicy />
        <div class="col-12 flex flex-row flex-wrap justify-content-between">
          <Button label="Close" @click.throttle="() => (showPrivacyPolicy = false)" />
        </div>
      </div>
    </Dialog>
    <Menubar class="border-noround" :model="shortcuts" v-if="!fullScreen">
      <template #end>
        <div class="flex border-solid border-round surface-border p-1" @click.throttle="(evt: Event) => menu.toggle(evt)" aria-haspopup="true" aria-controls="overlay_menu">
          <Avatar size="large" class="mr-2" :label="avatarLabel" />
          <span class="inline-flex flex-column">
            <span class="font-bold" style="text-wrap: nowrap">{{ user?.name }}</span>
            <span class="text-sm">{{ company?.name }}</span>
          </span>
        </div>
        <Menu ref="menu" :popup="true" :model="items" />
      </template>
    </Menubar>
    <RouterView @user-changed="(user: User) => ressurectSession(user)" @privacy-policy="() => (showPrivacyPolicy = true)" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.p-paginator.p-component) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

::v-deep(.p-datatable-header) {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

::v-deep(.router-link-active) {
  border-bottom: 3px solid #0b8985;
}
</style>
