import axios, { withRecaptchaHeader } from "@/plugins/axios";
import { SuccessResponse } from "@/types/response";
import { User } from "@/types/user";

export function authenticate(email: string, password: string, recaptchaToken: string) {
  const cfg = withRecaptchaHeader(recaptchaToken);
  return axios.post<SuccessResponse<User>>("auth/authenticate", { email, password }, cfg).then((resp) => new User(resp.data.result));
}

export function updatePassword(token: string, password: string) {
  return axios.post<void>("auth/update-password", { token, password });
}

export function completeSignUp(invite: string, name: string, password: string) {
  return axios.post<void>("auth/complete-sign-up", { invite, name, password });
}

export function refreshToken() {
  return axios.post<SuccessResponse<User>>("auth/refresh-token").then((resp) => new User(resp.data.result));
}

export function signoff() {
  return axios.post<void>("auth/sign-off");
}

export function requestPasswordReset(email: string, recaptchaToken: string) {
  const cfg = withRecaptchaHeader(recaptchaToken);
  return axios.post<void>("auth/request-password-reset", { email }, cfg);
}
