import { createApp } from "vue";
import App from "./app.vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import VueClick from "vue-click";
import router from "./router";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "@/assets/css/global.css";
import "primevue/resources/themes/tailwind-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/brands.css";
import "@fortawesome/fontawesome-free/css/solid.css";

const app = createApp(App);
app.directive("tooltip", Tooltip);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(VueClick);
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY, loaderOptions: { autoHideBadge: true } });

app.mount("#app");
