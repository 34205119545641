import { createRouter, createWebHistory, Router, RouteRecordRaw, RouterOptions } from "vue-router";
const LoginView = () => import("@/views/login.vue");
const ResetPassword = () => import("@/views/reset-password.vue");
const ForgotPassword = () => import("@/views/forgot-password.vue");
const CreateTest = () => import("@/views/create-test.vue");
const UpdateTest = () => import("@/views/update-test.vue");
const PatientList = () => import("@/views/patient-list.vue");
const TestList = () => import("@/views/test-list.vue");
const Dashboard = () => import("@/views/dashboard.vue");
const DoctorList = () => import("@/views/doctor-list.vue");
const InvoiceList = () => import("@/views/invoice-list.vue");
const InvoiceCreate = () => import("@/views/create-invoice.vue");
const InvoiceUpdate = () => import("@/views/update-invoice.vue");
const IncompleteInvoiceList = () => import("@/views/incomplete-invoice-list.vue");
const CompletedInvoiceList = () => import("@/views/completed-invoice-list.vue");
const CreateTestReport = () => import("@/views/create-test-report.vue");
const FullScreenReport = () => import("@/views/full-screen-report.vue");
const UserProfile = () => import("@/views/user-profile.vue");
const UserWelcome = () => import("@/views/user-welcome.vue");
const UserList = () => import("@/views/user-list.vue");
const CompanyProfile = () => import("@/views/company-profile.vue");
const CompanyRegistration = () => import("@/views/company-registration.vue");
const ReferralReport = () => import("@/views/referral-report.vue");
const SalesDetails = () => import("@/views/sales-details.vue");
const SalesSummary = () => import("@/views/sales-summary.vue");
const TestPackagesList = () => import("@/views/test-package-list.vue");
const PriceIndexList = () => import("@/views/price-index-list.vue");
const CreatePriceIndex = () => import("@/views/create-price-index.vue");
const UpdatePriceIndex = () => import("@/views/update-price-index.vue");
const Error403 = () => import("@/views/error-403.vue");
const Error404 = () => import("@/views/error-404.vue");
import * as perms from "@/helper/permission";

export interface RouteMeta {
  requiresAuth?: boolean;
  fullScreen?: boolean;
}

const routes: Readonly<RouteRecordRaw[]> = [
  { path: "/login", name: "login", component: LoginView, meta: { fullScreen: true } },
  { path: "/", name: "home", component: Dashboard, meta: { requiresAuth: true } },
  { path: "/forgot-password", name: "forgot-password", component: ForgotPassword, meta: { requiresAuth: false, fullScreen: true } },
  { path: "/reset-password/:token", name: "reset-password", component: ResetPassword, meta: { requiresAuth: false, fullScreen: true }, props: true },
  { path: "/patients", name: "patients", component: PatientList, meta: { requiresAuth: true } },
  { path: "/tests", name: "tests", component: TestList, meta: { requiresAuth: true } },
  { path: "/tests/new", name: "create-test", component: CreateTest, meta: { requiresAuth: true } },
  { path: "/price-list", name: "price-list", component: PriceIndexList, meta: { requiresAuth: true } },
  { path: "/price-list/create", name: "create-price-list", component: CreatePriceIndex, meta: { requiresAuth: true } },
  { path: "/price-list/:id", name: "update-price-list", component: UpdatePriceIndex, meta: { requiresAuth: true }, props: true },
  { path: "/tests/:id", name: "view-test", component: UpdateTest, meta: { requiresAuth: true }, props: true },
  { path: "/doctors", name: "doctors", component: DoctorList, meta: { requiresAuth: true } },
  { path: "/invoices", name: "invoices", component: InvoiceList, meta: { requiresAuth: true } },
  { path: "/users", name: "users", component: UserList, meta: { requiresAuth: true } },
  { path: "/users/profile", name: "user-profile", component: UserProfile, meta: { requiresAuth: true } },
  { path: "/invoices/new", name: "create-invoice", component: InvoiceCreate, meta: { requiresAuth: true } },
  { path: "/invoices/:id", name: "update-invoice", component: InvoiceUpdate, meta: { requiresAuth: true }, props: true },
  { path: "/invoices/incomplete", name: "incomplete-invoices", component: IncompleteInvoiceList, meta: { requiresAuth: true } },
  { path: "/invoices/completed", name: "completed-invoices", component: CompletedInvoiceList, meta: { requiresAuth: true } },
  { path: "/invoices/referral-report", name: "referral-report", component: ReferralReport, meta: { requiresAuth: true } },
  { path: "/invoices/sales-details", name: "sales-details", component: SalesDetails, meta: { requiresAuth: true } },
  { path: "/test-reports/new/:id", name: "create-test-report", component: CreateTestReport, meta: { requiresAuth: true }, props: true },
  { path: "/test-reports/:id", name: "view-test-report", component: FullScreenReport, meta: { fullScreen: true }, props: true },
  { path: "/test-package", name: "test-package", component: TestPackagesList, meta: { requiresAuth: true } },
  { path: "/welcome/:invite", name: "user-sign-up", component: UserWelcome, meta: { fullScreen: true }, props: true },
  { path: "/company/profile", name: "company-profile", component: CompanyProfile, meta: { requiresAuth: true } },
  { path: "/company/register", name: "company-registration", component: CompanyRegistration, meta: { requiresAuth: true } },
  { path: "/invoice/sales-summary", name: "sales-summary", component: SalesSummary, meta: { requiresAuth: true } },
  { path: "/error-403", name: "error-403", component: Error403, meta: { fullScreen: true } },
  { path: "/:pathMatch(.*)*", name: "error-404", component: Error404, meta: { fullScreen: true } },
];

export const menuItems = [
  { label: "Home", icon: "fa-solid fa-home", command: () => router.push({ name: "home" }) },
  { label: "Patient", icon: "fa-solid fa-hospital-user", command: () => router.push({ name: "patients" }), requires: [perms.PERM_CREATE_PATIENT, perms.PERM_UPDATE_PATIENT, perms.PERM_LIST_PATIENTS] },
  { label: "Test", icon: "fa-solid fa-vial", command: () => router.push({ name: "tests" }), requires: [perms.PERM_CREATE_TEST, perms.PERM_UPDATE_TEST, perms.PERM_LIST_TESTS] },
  { label: "Doctor", icon: "fa-solid fa-user-doctor", command: () => router.push({ name: "doctors" }), requires: [perms.PERM_CREATE_DOCTOR, perms.PERM_UPDATE_DOCTOR, perms.PERM_LIST_DOCTORS] },
  { label: "Invoice", icon: "fa-solid fa-file-invoice-dollar", command: () => router.push({ name: "invoices" }), requires: [perms.PERM_CREATE_INVOICE, perms.PERM_UPDATE_INVOICE, perms.PERM_LIST_INVOICES] },
  { label: "Pending Test Reports", icon: "fa-solid fa-file-medical", command: () => router.push({ name: "incomplete-invoices" }), requires: [perms.PERM_LIST_PENDING_REPORTS, perms.PERM_CREATE_TEST_REPORT] },
  { label: "Completed Test Reports", icon: "fa-solid fa-file-medical", command: () => router.push({ name: "completed-invoices" }), requires: [perms.PERM_LIST_COMPLETED_REPORTS] },
  { label: "Users", icon: "fa-solid fa-users", command: () => router.push({ name: "users" }), requires: [perms.PERM_INVITE_USER, perms.PERM_UPDATE_USER] },
  { label: "Company Profile", icon: "fa-solid fa-building", command: () => router.push({ name: "company-profile" }), requires: [perms.PERM_UPDATE_COMPANY] },
  { label: "Company Registration", icon: "fa-solid fa-building", command: () => router.push({ name: "company-registration" }), requires: [perms.PERM_CREATE_COMPANY] },
  { label: "View Referrals", icon: "fa-solid fa-rectangle-list", command: () => router.push({ name: "referral-report" }), requires: [perms.PERM_VIEW_REFERRAL_REPORT, perms.PERM_DOWNLOAD_REFERRAL_REPORT] },
  { label: "Test Packages", icon: "fa-solid fa-box", command: () => router.push({ name: "test-package" }), requires: [perms.PERM_CREATE_TEST_PACKAGE, perms.PERM_UPDATE_TEST_PACKAGE, perms.PERM_LIST_TEST_PACKAGES] },
  { label: "Price List", icon: "fa-solid fa-dollar", command: () => router.push({ name: "price-list" }), requires: [perms.PERM_CREATE_PRICE_LIST, perms.PERM_VIEW_PRICE_LISTS, perms.PERM_UPDATE_PRICE_LIST] },
  { label: "Sales Details", icon: "fa-solid fa-rectangle-list", command: () => router.push({ name: "sales-details" }), requires: [perms.PERM_VIEW_SALES_DETAIL_REPORT, perms.PERM_DOWNLOAD_SALES_DETAIL_REPORT] },
  { label: "Sales Summary", icon: "fa-solid fa-rectangle-list", command: () => router.push({ name: "sales-summary" }), requires: [perms.PERM_VIEW_SALES_SUMMARY] },
];

const router: Router = createRouter({
  history: createWebHistory(),
  routes: routes,
} as RouterOptions);

export default router;
