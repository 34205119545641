import axios from "@/plugins/axios";
import { Company, Branch } from "@/types/company";
import { SuccessResponse } from "@/types/response";
import { User } from "@/types/user";

export function fetchCompanies() {
  return axios.get<SuccessResponse<Company[]>>("company").then((resp) => resp.data.result.map((c) => new Company(c)));
}

export function onboardCompany(company: Company, user: User) {
  return axios.post<void>("company/onboard", { company, user });
}

export function updateCompany(company: Company) {
  return axios.put<void>("company", company);
}

export function fetchSelfCompany() {
  return axios.get<SuccessResponse<Company>>("company/self").then((resp) => new Company(resp.data.result));
}

export function setLocation(branchId: string, rememberMe: boolean) {
  return axios.post<void>("company/set-location", { branchId, noExpiry: rememberMe });
}

export function getLocation() {
  return axios.get<SuccessResponse<Branch>>("company/get-location").then((resp) => new Branch(resp.data.result));
}
