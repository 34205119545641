export class Company {
  id: string;
  name: string;
  address: string;
  contact_no: string;
  sender_id: string;
  branches: Branch[];
  telegram_token: string;
  viber_token: string;

  constructor(props: { id: string; name: string; address: string; contact_no: string; branches: Branch[]; sender_id: string; telegram_token?: string; viber_token?: string }) {
    this.id = props.id;
    this.name = props.name;
    this.address = props.address;
    this.contact_no = props.contact_no;
    this.branches = props.branches;
    this.sender_id = props.sender_id;
    this.telegram_token = props?.telegram_token ?? "";
    this.viber_token = props?.viber_token ?? "";
  }
}

export class Branch {
  id: string;
  name: string;
  branch_code: string;
  address: string;
  contact_no: string;

  constructor(props: { id: string; name: string; branch_code: string; address: string; contact_no: string }) {
    this.id = props.id;
    this.name = props.name;
    this.branch_code = props.branch_code;
    this.address = props.address;
    this.contact_no = props.contact_no;
  }
}
